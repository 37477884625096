import React, { useState, useContext } from "react";
import ProjectsMenu from "./ProjectsMenu";
import Project from "./Project";
import { DataContext } from "../DataContext/DataContext";


function Projects(){ 

    const {data, loading, error} = useContext(DataContext);
    const [selectedProject, setSelectedProject] = useState(null);

    if(loading) return <p>Loading...</p>;
    if(error) return <p>Error:{error}</p>;

    const handleProjectsSelection = (description) => {
        setSelectedProject(description);
    }
    return (
        <div className='Projects'>
            <ProjectsMenu
                data={data["cv-projects"]}
                onProjectSelect={handleProjectsSelection}       
            />
            {selectedProject && <Project description={selectedProject.description} link ={selectedProject.link}/>}
        </div>
    )
}

export default Projects