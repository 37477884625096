import "./Header.css";
import profilePicture from "../../Resources/Poza.png";
import WhatsAppButton from "./WhatsAppButton/WhatsAppButton";

function Header() {
  return (
    <div className="Header greeting-container d-flex flex-column align-items-center text-center text-white">
      <div className="ProfileImg my-3">
        <img
          src={profilePicture}
          className="mypicture img-fluid"
          alt="Profile"
        ></img>
      </div>
      <h1 className="my-3">Hi, I'm Alex.</h1>
      <p className="fs-4 my-3">
        Front End Developer who focuses on writing clean, elegant, and efficient
        code.
      </p>
      <div className="my-3">
        <WhatsAppButton phoneNumber="+40741062732" message="" />
      </div>
    </div>
  );
}

export default Header;
