import React from "react";
import './ProjectsMenu.css';

function ProjectsMenu({ data, onProjectSelect }) {

  const handleClick = (description) => {
    onProjectSelect(description);  // Pass selected project's description to parent
  };

  return (
    <div className="ProjectsMenu">
      {data.map((item, index) => (
        <button key={index} onClick={() => handleClick(item)} className="project-button">
          {item.title}
        </button>
      ))}
    </div>
  );
}

export default ProjectsMenu;
