import React, {useState} from "react";
import bootstrap from "bootstrap/dist/css/bootstrap.css";
import Header from "./Components/Header/Header";
import Menu from "./Components/Menu/Menu";
import Content from "./Components/Content/Content";
import "./App.css";

function App() {
  const [activeSection, setActiveSection] = useState("about");  // Default state

  return (
    <div className="App container-fluid row">
      <div className="App-header col-md-4 rounded-4 p-3 m-2 mb-3">
        <Header />
      </div>
      <div className="App-content col-md rounded-4 m-md-3 m-lg-2 ms-2 px-0">
        <Menu setActiveSection={setActiveSection} />
        <Content activeSection={activeSection}/>
      </div>
    </div>
  );
}

export default App;
