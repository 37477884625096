import React from "react";
import "./Content.css";
import DataProvider from "../DataContext/DataContext";
import AboutMe from "../AboutMe/AboutMe";
import Projects from "../Projects/Projects";

function Content({ activeSection }) {

  return (
    <div className="Content rounded-4 my-3 p-3">
      <DataProvider>
        {activeSection === "about" && <AboutMe />}
        {activeSection === "projects" && <Projects />}
      </DataProvider>
    </div>
  );
}

export default Content;
