import React from "react";
import './Project.css';

function Project({description, link}) {

    return (
        <div className="Project">    
            <p dangerouslySetInnerHTML={{ __html: description.replace(/\n/g, "<br />") }} />
            <a href={link} className="link-to-project">Link to the project</a>
        </div>
    )
}

export default Project